import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";
import { ErrorResponse } from "../../shared/type/ErrorResponseType";
import { AxiosError } from "axios";

export type ShippingProgress = {
  stop: string;
  status: "waiting" | "in-transit" | "arrived" | "delivered" | "success" | "exception";
  store_number: string;
  store_name: string;
};

export type ShippingInfo = {
  dc_code: string;
  truck_plate_number: string;
  truck_type: string;
  driver_mobile_number: string;
  lat: string | null;
  long: string | null;
  last_location_timestamp: string | null;
  eta_start: string;
  eta_end: string;
  eta: string;
  stop: string;
  status: "waiting" | "in-transit" | "arrived" | "delivered" | "success" | "exception";
  status_updated_at: string;
  active: boolean;
  progress: ShippingProgress[] | null;
};

export type StoreOwner = {
  store_number: string;
  store_name: string;
  amphoe: string;
  province: string;
  shipping_infos: ShippingInfo[] | null;
  uploaded_time: string;
  Lat: string;
  Long: string;
};

export type TrackingState = {
  storeOwner: StoreOwner | null;
};

const initialValues: TrackingState = {
  storeOwner: null,
};

export const fetchStoreTracking = createAsyncThunk<
  StoreOwner,
  {
    trackingNumber: string;
    companyCode: string;
  },
  {
    rejectValue: ErrorResponse;
  }
>("request/store_owner", async ({ trackingNumber, companyCode }, { rejectWithValue }) => {
  try {
    const response = await ApiClient.get(`${ApiEndPoint.STORE_TRACKING}/${trackingNumber}`, {
      headers: { "X-Company-Code": companyCode },
    });
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      console.log(err);
      return rejectWithValue(err.response?.data);
    }
  }
});

const storeTracking = createSlice({
  name: "storeTracking",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoreTracking.fulfilled, (state, action) => {
      state.storeOwner = action.payload;
    });

    builder.addCase(fetchStoreTracking.rejected, (state) => {
      state.storeOwner = null;
    });

    builder.addCase(fetchStoreTracking.pending, (state) => {
      state.storeOwner = null;
    });
  },
});

export const storeOwnerSelector = (store: RootState) => store.storeTracking.storeOwner;
export default storeTracking.reducer;

export const getStoresTracking = (trackingNumber: string, companyCode: string) =>
  ApiClient.get(`${ApiEndPoint.STORE_TRACKING}/${trackingNumber}`, {
    headers: { "X-Company-Code": companyCode },
  }).then((res) => res.data);
